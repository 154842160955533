import React, { useState } from 'react'
import { Link } from 'gatsby'
import Logo from '../assets/logo.svg'

const Header = () => {
    const [ isOpen, setIsOpen ] = useState(false)
    const handleClick = () => setIsOpen(!isOpen)

    return (
        <div className="sticky top-0 z-99999">
            <nav
                className="flex items-center z-50 justify-between flex-wrap py-2 lg:px-5 shadow bg-white">
                <div className="flex justify-between lg:w-auto w-full border-solid border-gray-300 pb-0">
                    <div className="flex items-center justify-center">
                        <Link to='/' className="text-white pointer"><Logo className="logo" alt="avatar"/></Link>
                    </div>
                    <div className="block lg:hidden pr-3 ">
                        <button
                            onClick={()=>handleClick()}
                            id="nav"
                            className="flex items-center z-50 px-3 mt-4 p-2 border-2 border-textColor rounded text-textColor">
                            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                            </svg>
                        </button>
                    </div>
                </div>
    
                <div className={`menu w-full flex-grow lg:flex ${isOpen ? 'block' : 'hidden'} lg:items-center text-center lg:w-auto lg:px-3 px-8`}>
                    <div className="text-lg leading-loose flex-grow">
                        <Link to='/'
                            className="leading-loose block mt-4 lg:inline-block lg:mt-0 text-textColor hover:text-mainColor px-4 py-2 rounded  mr-2">
                    Features
                        </Link>
                        <Link to='/'
                            className="block mt-4 lg:inline-block lg:mt-0 text-textColor hover:text-mainColor px-4 py-2 rounded  mr-2">
                    Download
                        </Link>
                        <Link to='/pricing'
                            className="block mt-4 lg:inline-block lg:mt-0 text-textColor hover:text-mainColor px-4 py-2 rounded mr-2">
                    Pricing
                        </Link>
                        <div className="dropdown inline-block">
                            <div
                                className="block mt-4 lg:inline-block lg:mt-0 text-textColor hover:text-mainColor px-4 py-2 rounded  mr-2">
                                    Help
                            </div>
                            <ul className="dropdown-menu absolute hidden text-gray-700 pt-1 bg-white rounded-md">
                                <li className="p-3"><Link className="rounded-t text-textColor block hover:text-mainColor" to="/help">Help Pages</Link></li>
                                <li className="p-3"><Link className="text-textColor block hover:text-mainColor" to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                        <Link to='/blog'
                            className="block mt-4 lg:inline-block lg:mt-0 text-textColor hover:text-mainColor px-4 py-2 rounded  mr-2">
                    Blog
                        </Link>
                        <Link to='/company'
                            className="block mt-4 lg:inline-block lg:mt-0 text-textColor hover:text-mainColor px-4 py-2 rounded mr-2">
                    Company
                        </Link>
                    </div>
                    <div className="flex justify-center">
                        <button
                            className="block text-md px-6 py-2 text-mainColor rounded bg-yellowColor mt-4  lg:mt-0">
                                Login
                        </button>
    
                    </div>
                </div>
    
            </nav>
        </div>
    )

}
export default Header